import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { FC, useEffect, useRef } from 'react';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'theme';
import { md } from 'theme/styles/mediaQueries';
import { Text } from 'components/Layout';
import NoteCard from 'components/Notes/NoteCard';
import { TipCard } from 'components/Cards';
import { Conversation } from '../../../types/resources';

const Wrapper = styled.div`
  padding: ${theme.spacing.space12} ${theme.spacing.space16};
  overflow-y: scroll;
  background-color: ${theme.colors.gray1};
  height: 100%;
  display: flex;
  flex-direction: column-reverse;

  ${md(`
    padding: ${theme.spacing.space16} ${theme.spacing.space24};
    background-color: ${theme.colors.white};
    height: 45vh;
  `)}
`;

const MessageWrapper = styled.div<{ index: number }>`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.space16};
  margin-bottom: ${({ index }) => (index !== 0 ? theme.spacing.space16 : 0)};
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: ${theme.spacing.space16};
`;

const NoMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const TipCardWrapper = styled.div`
  order: 1;
  margin-bottom: ${theme.spacing.space16};
`;

const ObserverWrapper = styled.div`
  order: 2;
`;

interface ConversationCardContentProps {
  conversation: Conversation;
  messages: {
    date: string;
    items: {
      id: string;
      fullname: string;
      content: string;
      isEditable?: boolean;
      processed: boolean;
      writtenByMe?: boolean;
    }[];
  }[];
  showGroupedTipCard?: boolean;
  fetchMore?: () => void;
}

const ConversationCardContent: FC<ConversationCardContentProps> = ({
  conversation,
  messages,
  showGroupedTipCard = false,
  fetchMore,
}) => {
  const { t } = useTranslation();
  const observerTarget = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchMore?.();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [fetchMore]);

  return (
    <Wrapper>
      {showGroupedTipCard && (
        <TipCardWrapper>
          <TipCard
            title={t('opportunity.conversation.grouped_tip_card.title')}
            content={t('opportunity.conversation.grouped_tip_card.description')}
            type="info"
          />
        </TipCardWrapper>
      )}
      <ObserverWrapper ref={observerTarget} />
      {messages.length === 0 && (
        <NoMessageWrapper>
          <Text
            content={t('opportunity.conversation.no_message')}
            fontStyle="body3"
            textAlign="center"
          />
        </NoMessageWrapper>
      )}
      {messages.map((message, index) => {
        const formattedDate = format(parseISO(message.date), 'eeee dd MMMM', {
          locale: fr,
        });

        return (
          <MessageWrapper
            key={`conversation-content-message-${index}-${uniqueId()}`}
            index={index}
          >
            <Text
              content={formattedDate}
              fontStyle="body3"
              weight="bold"
              textAlign="center"
            />
            <ItemsWrapper>
              {message.items.map((item) => (
                <NoteCard
                  conversation={conversation}
                  key={`conversation-content-message-item-${item.id}`}
                  onUpdate={() => {}}
                  editButtonLabel={t('opportunity.notes.button.edit')}
                  {...item}
                />
              ))}
            </ItemsWrapper>
          </MessageWrapper>
        );
      })}
    </Wrapper>
  );
};

export default ConversationCardContent;
