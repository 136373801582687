import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ClientCard } from 'components/Cards';

import FileDrop from '../../../components/FileDrop/FileDrop';
import { mergeFilesToPdf } from '../../../utils/pdf';

interface UploadConventionCardProps {
  onConfirmUpload: (blob: Blob) => void;
  loading?: boolean;
}

const UploadConventionCard: FC<UploadConventionCardProps> = ({
  onConfirmUpload,
  loading,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);

  const onDropFiles = (files: File[]) => {
    if (files.length) {
      setFiles(files);
    }
  };

  const confirm = useCallback(() => {
    if (files.length) {
      mergeFilesToPdf(files).then((value) => {
        onConfirmUpload?.(new Blob([value], { type: 'application/pdf' }));
      });
    }
  }, [files]);

  return (
    <ClientCard
      iconName="Upload"
      title={t('convention.first_upload')}
      buttons={[
        {
          content: 'Confirmer',
          primary: true,
          disabled: !files.length,
          $loading: loading,
          onClick: confirm,
        },
      ]}
    >
      <FileDrop
        onDrop={onDropFiles}
        multiple={true}
        maxFiles={5}
        disabled={loading}
      />
    </ClientCard>
  );
};

export default UploadConventionCard;
