import { FormList } from 'components/Forms';
import { Flex, Text } from 'components/Layout';
import { UserValidation } from 'components/User';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useApi } from '../../../../hooks/useApi';
import {
  FormFull,
  FormStatus,
  FormTemplate,
  FormTemplateId,
  ProcedureFull,
  ProcedureSpouseFull,
  ProcedureStepFull,
  ProcedureStepStatus,
  ProcedureStepType,
  User,
} from '../../../../types/resources';
import { Button } from '../../../../components/Buttons';
import { Modal, ModalHeader } from '../../../../components/Modal';
import InputSelect from '../../../../components/FormTemplate/Fields/InputSelect';
import { InputText } from '../../../../components/FormTemplate/Fields/InputText';
import { ModalBody } from 'react-bootstrap';
import { formatFormTitle } from '../../../../utils/format';

type FormCreate = {
  form_template_id: FormTemplateId;
  name: string;
  concerned_spouse_id?: string | null;
};

type DeleteFormModalProps = {
  form: FormFull;
  opened: boolean;
  loading?: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

const DeleteFormModal: FC<DeleteFormModalProps> = ({
  form,
  loading,
  opened,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal opened={opened} onClose={onClose} width={'80%'}>
      <ModalHeader iconName={'Delete'} content={t('forms.delete.title')} />

      <ModalBody>
        <Text fontStyle={'body1'}>
          <Trans
            i18nKey="forms.delete.description"
            values={{ title: formatFormTitle(form) }}
          />
        </Text>
      </ModalBody>

      <Flex justify={'end'} alignSelf={'end'}>
        <Button
          content={t('forms.delete.confirm')}
          marginRight={{ xs: 'space16' }}
          $loading={loading}
          onClick={onConfirm}
        />
        <Button content={'Annuler'} onClick={onClose} primary />
      </Flex>
    </Modal>
  );
};

type AddFormModalProps = {
  opened: boolean;
  loading?: boolean;
  onConfirm: (data: FormCreate) => void;
  onClose: () => void;
  spouses: User[];
};

const AddFormModal: FC<AddFormModalProps> = ({
  opened,
  loading,
  onConfirm,
  onClose,
  spouses,
}) => {
  const { t } = useTranslation();
  const [templateId, setTemplateId] = useState<FormTemplateId | undefined>(
    undefined,
  );
  const [spouseId, setSpouseId] = useState<string | null | undefined>(
    undefined,
  );
  const [name, setName] = useState<string | null | undefined>(undefined);
  const spousesOptions = [
    { label: 'Formulaire commun', value: null },
    ...spouses.map((s) => ({
      label: s.full_name,
      value: s.id,
    })),
  ];

  const { execute: getFormsTemplates, state: getFormsTemplatesState } =
    useApi<Pick<FormTemplate, 'id' | 'title'>[]>(`/forms-templates`);
  const formsTemplates = getFormsTemplatesState.data?.value ?? [];

  const formsTemplatesOptions = formsTemplates.map((ft) => ({
    label: t(`forms.names.${ft.id}`),
    value: ft.id,
  }));

  useEffect(() => {
    if (opened) {
      getFormsTemplates();
      setTemplateId(undefined);
      setName(undefined);
    }
  }, [opened]);

  return (
    <Modal opened={opened} onClose={onClose} width={'80%'} height={'70%'}>
      <ModalHeader
        iconName={'Document'}
        content={'Nouvelle demande de formulaire'}
      />

      <Flex marginBottom={{ xs: 'space16' }}>
        <InputSelect
          onChange={(value) => {
            const selectedTemplate = formsTemplates.find(
              (ft) => ft.id === value,
            );
            setTemplateId(selectedTemplate?.id);
            setName(selectedTemplate?.title ?? '');
          }}
          options={formsTemplatesOptions}
          placeholder={t('forms.request.type_placeholder')}
          label={t('forms.request.type')}
          size="small"
          value={templateId}
          required
          width="100%"
        />
      </Flex>

      {templateId !== undefined && (
        <Flex marginBottom={{ xs: 'space16' }}>
          <InputSelect
            onChange={(value) => {
              const selectedSpouse =
                value === null
                  ? value
                  : spouses.find((s) => s.id === value)?.id;
              setSpouseId(selectedSpouse);
            }}
            options={spousesOptions}
            placeholder={t('upload.spouse_selection_placeholder')}
            label={t('upload.spouse_selection')}
            size="small"
            required
            width="100%"
          />
        </Flex>
      )}

      {templateId !== undefined && spouseId !== undefined && (
        <Flex marginBottom={{ xs: 'space16' }}>
          <InputText
            onChange={(value) => {
              setName(value.target.value ?? '');
            }}
            placeholder={t('forms.request.name_placeholder')}
            label={t('forms.request.name')}
            value={name ?? undefined}
            $size="small"
            labelFontStyle={'label'}
            required
            width="100%"
          />
        </Flex>
      )}

      <Flex marginTop={{ xs: 'space40' }} justify={'end'} alignSelf={'end'}>
        <Button
          content={'Ajouter le formulaire'}
          marginRight={{ xs: 'space16' }}
          $loading={loading}
          onClick={() => {
            if (templateId && name) {
              onConfirm({
                form_template_id: templateId,
                name,
                concerned_spouse_id: spouseId,
              });
            }
          }}
        />
        <Button content={'Annuler'} onClick={onClose} primary />
      </Flex>
    </Modal>
  );
};

type FormViewProps = {
  procedure: ProcedureFull;
  refetchProcedure: () => void;
  spouses: ProcedureSpouseFull[];
};

const FormView: FC<FormViewProps> = ({
  procedure,
  refetchProcedure,
  spouses,
}) => {
  const { t } = useTranslation();
  const [warningModalState, setWarningModalState] = useState(false);
  const [addModalState, setAddModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [formToDelete, setFormToDelete] = useState<FormFull | null>(null);
  const spousesUsers = spouses.map((s) => s.spouse);

  const { execute: getForms, state: getFormsState } = useApi<FormFull[]>(
    `/procedures/${procedure.id}/forms`,
  );

  const { execute: getStep, state: getStepState } = useApi<ProcedureStepFull>(
    `/procedures/${procedure.id}/steps/${ProcedureStepType.FORM}`,
  );

  const { execute: createForm, state: createFormState } = useApi(
    `/procedures/${procedure.id}/forms`,
    {
      method: 'POST',
      onSuccess: () => {
        closeModals();
        refetchProcedure();
      },
    },
  );

  const { execute: deleteForm, state: deleteFormState } = useApi(
    `/procedures/${procedure.id}/forms`,
    {
      method: 'DELETE',
      onSuccess: () => {
        closeModals();
        getForms();
      },
    },
  );

  const deleteFormCb = useCallback(() => {
    if (formToDelete) {
      deleteForm({
        endpoint: `/procedures/${procedure.id}/forms/${formToDelete.id}`,
      });
    }
  }, [formToDelete]);

  const step = getStepState.data?.value;
  const validations = step?.validations ?? [];
  const forms = getFormsState.data?.value ?? [];

  const formsFinish = forms.filter((f) => f.status === FormStatus.VALIDATED);
  const formsCompleted = forms.filter((f) => f.status === FormStatus.COMPLETED);
  const formsToReview = forms.filter((f) => f.status === FormStatus.IN_REVIEW);
  const formInCompletion = forms.filter(
    (f) => f.status === FormStatus.TO_COMPLETE,
  );
  const spouse1Validation = validations.find(
    (v) => v.user_id === procedure.spouse1_id,
  );
  const spouse2Validation = validations.find(
    (v) => v.user_id === procedure.spouse2_id,
  );

  const openWarningModal = () => {
    setWarningModalState(true);
    setAddModalState(false);
    setDeleteModalState(false);

    setFormToDelete(null);
  };

  const openAddModal = () => {
    setWarningModalState(false);
    setAddModalState(true);
    setDeleteModalState(false);

    setFormToDelete(null);
  };

  const openDeleteModal = (form: FormFull) => {
    setWarningModalState(false);
    setAddModalState(false);
    setDeleteModalState(true);

    setFormToDelete(form);
  };

  const closeModals = () => {
    setWarningModalState(false);
    setAddModalState(false);
    setDeleteModalState(false);

    setFormToDelete(null);
  };

  const onClickAddButton = () => {
    if (step && step.status === ProcedureStepStatus.VALIDATED) {
      openWarningModal();
    } else {
      openAddModal();
    }
  };

  useEffect(() => {
    getForms();
    getStep();
  }, [procedure]);

  return (
    <>
      <Flex marginBottom={{ xs: 'space72' }} desktopOnly>
        {procedure.spouse1 && (
          <UserValidation
            user={procedure.spouse1}
            valid={Boolean(spouse1Validation)}
            letters="C1"
            validationDate={
              spouse1Validation
                ? format(
                    new Date(spouse1Validation.created_at),
                    "'Le' dd/MM/yyyy 'à' HH:mm",
                  )
                : undefined
            }
          />
        )}
        {procedure.spouse2 && (
          <UserValidation
            user={procedure.spouse2}
            valid={Boolean(spouse2Validation)}
            letters="C2"
            validationDate={
              spouse2Validation
                ? format(
                    new Date(spouse2Validation.created_at),
                    "'Le' dd/MM/yyyy 'à' HH:mm",
                  )
                : undefined
            }
          />
        )}
      </Flex>
      <FormList
        title={t('forms_opportunity.in_completion')}
        count={t('forms_opportunity.in_completion_count', {
          count: formInCompletion?.length,
        })}
        data={formInCompletion}
        opportunityId={procedure.id}
        onDelete={openDeleteModal}
      />
      <FormList
        title={t('forms_opportunity.to_review')}
        count={t('forms_opportunity.to_review_count', {
          count: formsToReview?.length,
        })}
        data={formsToReview}
        opportunityId={procedure.id}
        onDelete={openDeleteModal}
      />
      <FormList
        title={t('forms_opportunity.completed')}
        count={t('forms_opportunity.completed_count', {
          count: formsCompleted?.length,
        })}
        data={formsCompleted}
        opportunityId={procedure.id}
        onDelete={openDeleteModal}
      />
      <FormList
        title={t('forms_opportunity.finish')}
        count={t('forms_opportunity.finish_count', {
          count: formsFinish?.length,
        })}
        data={formsFinish}
        opportunityId={procedure.id}
        onDelete={openDeleteModal}
      />
      <Button
        iconLeft={{ name: 'Plus' }}
        content={t('documents.add')}
        onClick={onClickAddButton}
        marginTop={{ xs: 'space24' }}
      />

      {/* Warning modal */}
      <Modal opened={warningModalState}>
        <ModalHeader
          iconName={'Danger'}
          content={"Retour à l'étape formulaires"}
        />

        <Text
          fontStyle="body1"
          marginTop={{ xs: 'space16' }}
          content="En ajoutant un nouveau formulaire alors que vous avez déjà passé cette étape, le dossier va automatiquement être reset à l'étape formulaires."
        />

        <Flex marginTop={{ xs: 'space40' }} justify={'end'}>
          <Button
            content={'Oui, je confirme'}
            marginRight={{ xs: 'space16' }}
            onClick={openAddModal}
          />
          <Button content={"Non j'annule"} onClick={closeModals} primary />
        </Flex>
      </Modal>

      {/* Add new form modal */}
      <AddFormModal
        opened={addModalState}
        loading={createFormState.loading}
        onClose={closeModals}
        onConfirm={(data: FormCreate) => {
          createForm({ body: data });
        }}
        spouses={spousesUsers}
      />

      {/* Delete form modal */}
      {formToDelete && (
        <DeleteFormModal
          form={formToDelete}
          opened={deleteModalState}
          onClose={closeModals}
          loading={deleteFormState.loading}
          onConfirm={deleteFormCb}
        />
      )}
    </>
  );
};

export default FormView;
