import { UiSchemaCustom } from '.';

const wedding: UiSchemaCustom[] = [
  {
    'ui:order': [
      'DOM_CONJ_RUE',
      'DOM_CONJ_VILLE',
      'DOM_CONJ_CP',
      'DOM_CONJ',
      'WD_DOM_CONJ_SORT',
      'WD_LIQ_AUTH_DATE',
      'WD_PARTIE1_DELAI_COHAB',
      'WD_PARTIE2_DELAI_COHAB',
      'LIQ_AUTH_NOTAIRE_NOM',
      'LIQ_AUTH_NOTAIRE_PRENOM',
      'LIQ_AUTH_NOTAIRE_ADRESSE',
      'LIQ_AUTH_NOTAIRE_VILLE',
      'LIQ_AUTH_NOTAIRE_CP',
    ],
    DOM_CONJ: {
      'ui:widget': 'select',
      'ui:placeholder': 'Veuillez  choisir',
    },
    WD_DOM_CONJ_SORT: {
      'ui:widget': 'select',
      'ui:placeholder': 'Veuillez  choisir',
    },
    WD_LIQ_AUTH_DATE: {
      'ui:field': 'CustomDatepicker',
      'ui:placeholder': 'Choisir une date',
    },
    WD_PARTIE1_DELAI_COHAB: {
      'ui:widget': 'select',
      'ui:placeholder': '3 mois',
    },
    WD_PARTIE2_DELAI_COHAB: {
      'ui:widget': 'select',
      'ui:placeholder': '3 mois',
    },
    DOM_CONJ_RUE: {
      'ui:field': 'CustomField',
      'ui:placeholder': '1 rue de la paix',
    },
    DOM_CONJ_VILLE: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Paris',
    },
    DOM_CONJ_CP: {
      'ui:field': 'CustomField',
      'ui:placeholder': '75000',
    },
    LIQ_AUTH_NOTAIRE_NOM: {
      'ui:field': 'CustomField',
    },
    LIQ_AUTH_NOTAIRE_PRENOM: {
      'ui:field': 'CustomField',
    },
    LIQ_AUTH_NOTAIRE_ADRESSE: {
      'ui:field': 'CustomField',
    },
    LIQ_AUTH_NOTAIRE_VILLE: {
      'ui:field': 'CustomField',
    },
    LIQ_AUTH_NOTAIRE_CP: {
      'ui:field': 'CustomField',
    },
  },
  {
    'ui:order': ['WD_PRECISION_DOM_CONJ'],
    WD_PRECISION_DOM_CONJ: {
      'ui:field': 'CustomTextArea',
      'ui:placeholder': 'Précisions supplémentaires...',
    },
  },
];

export default wedding;
