import { FC } from 'react';
import styled from 'styled-components';

import { md } from 'theme/styles/mediaQueries';
import { theme } from 'theme';
import { HEADER_HEIGHT } from 'container/common/Header/Header';

import ConversationCardHeader from './Header';
import ConversationCardFooter from './Footer';
import ConversationCardContent from './Content';
import { Conversation, Role } from '../../../types/resources';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  flex-direction: column;
  position: relative;

  ${md(`
    position: static;
    display: block;
    height: auto;
    background-color: ${theme.colors.white};
    border-radius: 24px;
    border: 1px solid ${theme.colors.gray3};
  `)}
`;

interface ConversationCardProps {
  conversation: Conversation;
  recipients: {
    role: Role;
    full_name: string;
  }[];
  canSendMessage: boolean;
  messages: {
    date: string;
    items: {
      id: string;
      fullname: string;
      content: string;
      isEditable?: boolean;
      processed: boolean;
      writtenByMe?: boolean;
    }[];
  }[];
  onSendMessage: (content: { message: string; attachments: File[] }) => void;
  showGroupedTipCard?: boolean;
  fetchMore?: () => void;
  defaultContent?: string;
}

const ConversationCard: FC<ConversationCardProps> = ({
  conversation,
  recipients,
  messages,
  onSendMessage,
  fetchMore,
  canSendMessage,
  showGroupedTipCard = false,
  defaultContent = '',
}) => {
  return (
    <Wrapper>
      <ConversationCardHeader recipients={recipients} />
      <ConversationCardContent
        conversation={conversation}
        messages={messages}
        showGroupedTipCard={showGroupedTipCard}
        fetchMore={fetchMore}
      />
      <ConversationCardFooter
        onClickSend={onSendMessage}
        defaultContent={defaultContent}
        canSendMessage={canSendMessage}
      />
    </Wrapper>
  );
};

export default ConversationCard;
