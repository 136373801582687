import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

// Components
import { Text, Flex, VerticalLine } from 'components/Layout';
import { Button } from 'components/Buttons';
import { InputText } from 'components/FormTemplate/Fields/InputText';

import { theme } from 'theme';
import { regexes } from 'utils/regex';
import { handleRegisterEvent } from 'utils/analytics';
import { useApi } from '../../../hooks/useApi';
import { useAuth } from '../../../hooks/useAuth';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { InputTel } from '../../../components/FormTemplate/Fields/InputTel';
import InputCheckbox from '../../../components/FormTemplate/Fields/InputCheckbox';
import { EstimateFull, Prospect } from '../../../types/resources';
import { Modal } from '../../../components/Modal';
import { getRouteWithParams } from '../../../utils/router';
import { scrollToTop } from '../../../utils/layout';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { PublicAppRoutes } from '../../../PublicApp';
import { Link } from 'react-router-dom';
import { TextLink } from '../../../components/Text';
import { useViewport } from '../../../hooks/useViewport';

enum RegisterFormTerms {
  BusinessRelationship = 'businessRelationship',
  BusinessEmail = 'businessEmail',
  Terms = 'terms',
}

export interface QuoteRegisterModalProps {
  estimate: EstimateFull;
  opened: boolean;
  closeModal: () => void;
}

type PasswordInputType = 'password' | 'text';

type RegisterUserInput = {
  accept_marketing_contact?: boolean;
  accept_data_business_usage: boolean;
  accept_terms_date: boolean;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  password_confirmation: string;
  phone: string;
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.space16};
`;

const StyledInputCheckboxWrapper = styled.div`
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

type QuoteModalSignInFormProps = {
  prospect?: Prospect;
  onSuccess: () => void;
  toggleFormType: () => void;
};

const QuoteModalSignInForm: FC<QuoteModalSignInFormProps> = ({
  prospect,
  onSuccess,
  toggleFormType,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [passwordType, setPasswordType] =
    useState<PasswordInputType>('password');
  const { login, isLoading } = useAuth();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<RegisterUserInput>({
    defaultValues: {
      email: prospect?.email,
      phone: prospect?.phone ?? '',
    },
  });

  const onSubmit = useCallback(
    (data: RegisterUserInput) => {
      setError(null);

      if (data) {
        login({
          ...data,
          onSuccess,
          onError: (err: any) => {
            const errText =
              err?.response?.status === 429
                ? t('signin.errors.too_many_attempts')
                : t('signin.errors.unauthorized');
            setError(errText);
          },
        });
      }
    },
    [setError],
  );

  return (
    <Flex direction={{ xs: 'column', md: 'row' }}>
      <Flex
        style={{
          backgroundColor: theme.colors.green1,
          padding: theme.spacing.space48,
          justifyContent: 'center',
        }}
        flexGrow={1}
        flexBasis={'0'}
        direction={{ xs: 'column' }}
      >
        <Text
          content={t('quote.register.title')}
          fontStyle="heading2"
          color={theme.colors.gray1}
          marginBottom={{ xs: 'space24' }}
        />

        <Text
          fontStyle="body1"
          color={theme.colors.gray1}
          marginBottom={{ xs: 'space24' }}
        >
          <Trans i18nKey={'quote.signIn.register'} />
        </Text>

        <Button
          content={t('quote.signIn.register_submit')}
          type="submit"
          fullWidth
          onClick={toggleFormType}
          primary
        />
      </Flex>

      <Flex
        style={{
          backgroundColor: theme.colors.gray1,
          padding: theme.spacing.space48,
        }}
        flexGrow={1.2}
        flexBasis={'0'}
        direction={{ xs: 'column' }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Text
            content={t('quote.signIn.title')}
            fontStyle="heading2"
            color={theme.colors.black}
            marginBottom={{ xs: 'space8' }}
          />

          <Text
            fontStyle="body1"
            color={theme.colors.black}
            marginBottom={{ xs: 'space8' }}
          >
            <Trans i18nKey={'quote.signIn.subtitle'} />
          </Text>

          <InputText
            {...register('email', {
              required: t('error.form.required').toString(),
              pattern: {
                value: regexes.email,
                message: t('error.form.email').toString(),
              },
            })}
            error={errors.email}
            labelFontStyle="body1"
            labelWeight="medium"
            placeholder={t('signIn.form.email_placeholder')}
            width="100%"
            required
          />
          <InputText
            {...register('password', {
              required: t('error.form.required').toString(),
            })}
            type={passwordType}
            error={errors.password}
            labelFontStyle="body1"
            labelWeight="medium"
            placeholder={t('signIn.form.password_placeholder')}
            width="100%"
            icon={{
              name: passwordType === 'password' ? 'Hide' : 'Show',
            }}
            onClick={() => {
              setPasswordType((prev) =>
                prev === 'password' ? 'text' : 'password',
              );
            }}
            required
          />
          <Link
            style={{ alignSelf: 'flex-start' }}
            to={PublicAppRoutes.FORGOTTEN_PASSWORD}
          >
            {t('signIn.form.forgotten_password')}
          </Link>
          {error !== null ? (
            <Text
              textAlign={'center'}
              fontStyle="body2"
              color="red"
              content={error}
            />
          ) : null}
          <Flex marginTop={{ xs: 'space8' }}>
            <Button
              content={t('quote.signIn.submit')}
              type="submit"
              $loading={isLoading}
              fullWidth
              primary
            />
          </Flex>
        </Form>
      </Flex>
    </Flex>
  );
};

type QuoteModalRegisterFormProps = {
  estimate: EstimateFull;
  prospect?: Prospect;
  onSuccess: () => void;
  toggleFormType: () => void;
};

const QuoteModalRegisterForm: FC<QuoteModalRegisterFormProps> = ({
  estimate,
  prospect,
  onSuccess,
  toggleFormType,
}) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const { authenticateUser } = useAuth();
  const {
    control,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<RegisterUserInput>({
    defaultValues: {
      email: prospect?.email,
      phone: prospect?.phone ?? '',
    },
  });
  const [passwordType, setPasswordType] =
    useState<PasswordInputType>('password');
  const [confirmPasswordType, setConfirmPasswordType] =
    useState<PasswordInputType>('password');
  const { execute: registerCustomer, state: registerCustomerState } = useApi(
    `/customers/register`,
    { method: 'POST' },
  );

  const toggleTerm = (term: RegisterFormTerms) => {
    switch (term) {
      case RegisterFormTerms.BusinessRelationship:
        setValue(
          'accept_data_business_usage',
          !getValues('accept_data_business_usage'),
        );
        break;
      case RegisterFormTerms.BusinessEmail:
        setValue(
          'accept_marketing_contact',
          !getValues('accept_marketing_contact'),
        );
        break;
      case RegisterFormTerms.Terms:
        setValue('accept_terms_date', !getValues('accept_terms_date'));
        break;
      default:
        return;
    }
  };

  const onSubmit = async (data: RegisterUserInput) => {
    if (
      isFormValid() &&
      data &&
      getValues('accept_terms_date') &&
      getValues('accept_data_business_usage')
    ) {
      registerCustomer({
        body: {
          accept_marketing_contact: data.accept_marketing_contact,
          accept_data_business_usage: new Date(),
          accept_terms_date: new Date(),
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          password: data.password,
          phone: data.phone,
          estimate_id: estimate.id,
        },
        onSuccess: () => {
          handleRegisterEvent();
          authenticateUser({ onSuccess });
        },
        onError: () => {
          setError('email', {
            type: 'custom',
            message: t('register.form.errors.email').toString(),
          });
        },
      });
    }
  };

  const isFormValid = () => {
    let isValid = true;

    if (!getValues('accept_terms_date')) {
      setValue('accept_terms_date', false);
      isValid = false;
    }
    if (!getValues('accept_data_business_usage')) {
      setValue('accept_data_business_usage', false);
      isValid = false;
    }
    if (!getValues('phone')) {
      setError('phone', {
        type: 'custom',
        message: t('error.form.required').toString(),
      });
      isValid = false;
    }

    return isValid;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, isFormValid)}>
      <Flex direction={{ xs: 'column', lg: 'row' }}>
        <Flex
          style={{ backgroundColor: theme.colors.gray1 }}
          paddingTop={{ xs: 'space48' }}
          paddingBottom={{ xs: 'space4', lg: 'space48' }}
          paddingLeft={{ xs: 'space48' }}
          paddingRight={{ xs: 'space48' }}
          flexGrow={1.2}
          flexBasis={'0'}
          direction={{ xs: 'column' }}
        >
          <Text
            content={t('register.title')}
            fontStyle="heading2"
            color={theme.colors.black}
            marginBottom={{ xs: 'space24' }}
          />
          <Text
            fontStyle="body1"
            color={theme.colors.black}
            marginBottom={{ xs: 'space12' }}
          >
            <Trans i18nKey={'quote.register.subtitle'} />
          </Text>

          <Flex style={{ columnGap: '24px' }} marginBottom={{ xs: 'space12' }}>
            <InputText
              {...register('first_name', {
                required: t('error.form.required').toString(),
                pattern: regexes.lettersOnly,
              })}
              error={errors.first_name}
              label={t('register.form.firstname')}
              labelFontStyle="body1"
              labelWeight="medium"
              placeholder={t('register.form.firstname_placeholder')}
              width="100%"
              required
            />

            <InputText
              {...register('last_name', {
                required: t('error.form.required').toString(),
                pattern: regexes.lettersOnly,
              })}
              error={errors.last_name}
              label={t('register.form.lastname')}
              labelFontStyle="body1"
              labelWeight="medium"
              placeholder={t('register.form.lastname_placeholder')}
              width="100%"
              required
            />
          </Flex>

          <Flex direction={{ xs: 'column' }} marginBottom={{ xs: 'space12' }}>
            <InputText
              {...register('email', {
                required: t('error.form.required').toString(),
                pattern: {
                  value: regexes.email,
                  message: t('error.form.email').toString(),
                },
              })}
              error={errors.email}
              label={t('register.form.email')}
              labelFontStyle="body1"
              labelWeight="medium"
              placeholder={t('register.form.email_placeholder')}
              width="100%"
              required
            />
          </Flex>

          <Flex marginBottom={{ xs: 'space8' }}>
            <Controller
              key={`field_phone`}
              name={'phone'}
              rules={{
                required: t('error.form.required'),
                validate: (value: string | undefined) => {
                  if (value && !isValidPhoneNumber(value)) {
                    return t('error.form.phone').toString();
                  }
                },
              }}
              control={control}
              render={() => (
                <InputTel
                  type="tel"
                  error={errors.phone}
                  label={t('register.form.phone')}
                  labelFontStyle="body1"
                  labelWeight="medium"
                  placeholder={t('register.form.phone_placeholder')}
                  width="100%"
                  value={(getValues('phone') || prospect?.phone) ?? undefined}
                  onChange={(value) => {
                    setValue('phone', value);
                    clearErrors('phone');
                  }}
                  required
                />
              )}
            />
          </Flex>
        </Flex>

        {!isMobile && (
          <VerticalLine
            style={{
              marginTop: theme.spacing.space48,
              marginBottom: theme.spacing.space48,
            }}
          />
        )}

        <Flex
          style={{ backgroundColor: theme.colors.gray1 }}
          paddingTop={{ xs: 'space4', lg: 'space48' }}
          paddingBottom={{ xs: 'space48' }}
          paddingLeft={{ xs: 'space48' }}
          paddingRight={{ xs: 'space48' }}
          flexGrow={1}
          flexBasis={'0'}
          direction={{ xs: 'column' }}
        >
          <Flex marginBottom={{ xs: 'space12' }}>
            <InputText
              {...register('password', {
                required: t('error.form.required').toString(),
                pattern: {
                  value: regexes.password,
                  message: t('error.form.password').toString(),
                },
              })}
              type={passwordType}
              error={errors.password}
              label={t('register.form.password')}
              labelFontStyle="body1"
              labelWeight="medium"
              placeholder={t('register.form.password_placeholder')}
              width="100%"
              icon={{
                name: passwordType === 'password' ? 'Hide' : 'Show',
              }}
              onClick={() => {
                setPasswordType((prev) =>
                  prev === 'password' ? 'text' : 'password',
                );
              }}
              required
            />
          </Flex>

          <Flex marginBottom={{ xs: 'space12' }}>
            <InputText
              {...register('password_confirmation', {
                required: t('error.form.required').toString(),
                validate: (val: string) => {
                  if (watch('password') != val) {
                    return t('error.form.password_no_match').toString();
                  }
                },
              })}
              type={confirmPasswordType}
              error={errors.password_confirmation}
              label={t('register.form.password_confirmation')}
              labelFontStyle="body1"
              labelWeight="medium"
              placeholder={t('register.form.password_confirmation_placeholder')}
              width="100%"
              icon={{
                name: confirmPasswordType === 'password' ? 'Hide' : 'Show',
              }}
              onClick={() => {
                setConfirmPasswordType((prev) =>
                  prev === 'password' ? 'text' : 'password',
                );
              }}
              required
            />
          </Flex>

          <Flex
            direction={{ xs: 'column' }}
            marginBottom={{ xs: 'space24' }}
            style={{ rowGap: theme.spacing.space8 }}
          >
            <InputCheckbox
              label={t(`register.form.terms.checkbox1`)}
              fontStyle="body3"
              onChange={() =>
                toggleTerm(RegisterFormTerms.BusinessRelationship)
              }
              align="center"
              error={
                watch('accept_data_business_usage') !== undefined
                  ? !watch('accept_data_business_usage')
                  : undefined
              }
              checked={watch('accept_data_business_usage') ?? false}
            />
            <InputCheckbox
              label={t(`register.form.terms.checkbox2`)}
              fontStyle="body3"
              onChange={() => toggleTerm(RegisterFormTerms.BusinessEmail)}
              align="center"
            />
            <StyledInputCheckboxWrapper>
              <InputCheckbox
                label={t(`register.form.terms.checkbox3`)}
                fontStyle="body3"
                onChange={() => toggleTerm(RegisterFormTerms.Terms)}
                align="center"
                error={
                  watch('accept_terms_date') !== undefined
                    ? !watch('accept_terms_date')
                    : undefined
                }
                checked={watch('accept_terms_date') ?? false}
                dangerouslySetInnerHTML
              />
            </StyledInputCheckboxWrapper>
          </Flex>

          <Flex marginBottom={{ xs: 'space24' }}>
            <Button
              content={t('register.form.submit')}
              type="submit"
              $loading={registerCustomerState.loading}
              fullWidth
              iconLeft={{ name: 'ArrowRightSquare' }}
              primary
            />
          </Flex>

          <Flex justify={'center'}>
            <Text
              fontStyle={'body2'}
              style={{ marginRight: theme.spacing.space4 }}
            >
              <Trans i18nKey={'quote.register.signin'} />
            </Text>
            <TextLink
              onClick={toggleFormType}
              fontStyle={'body2'}
              weight={'bold'}
              content={t('quote.register.signin_link')}
            />
          </Flex>
        </Flex>
      </Flex>
    </Form>
  );
};

export type QuoteRegisterModalFormType = 'register' | 'signin';

const QuoteRegisterModal: FC<QuoteRegisterModalProps> = ({
  opened,
  closeModal,
  estimate,
}) => {
  const history = useHistory();
  const [formType, setFormType] =
    useState<QuoteRegisterModalFormType>('signin');
  const prospect = estimate.customer1_id
    ? undefined
    : estimate.prospects.reduce<Prospect | undefined>((min, p) => {
        if (!min || p.created_at < min.created_at) return p;
        else return min;
      }, undefined);

  const onSuccess = () => {
    history.push(
      getRouteWithParams('/recapitulatif-paiement/:id', {
        id: estimate.id,
      }),
    );
    scrollToTop();
  };

  const toggleFormType = () => {
    setFormType((prevState) =>
      prevState === 'register' ? 'signin' : 'register',
    );
  };

  // Reset default form type when opening modal
  useEffect(() => {
    if (opened) {
      setFormType('signin');
    }
  }, [opened]);

  return (
    <Modal
      opened={opened}
      height="auto"
      onClose={closeModal}
      width={'75%'}
      style={{ scrollbarWidth: 'thin', padding: 'unset' }}
    >
      {formType === 'register' && (
        <QuoteModalRegisterForm
          estimate={estimate}
          prospect={prospect}
          onSuccess={onSuccess}
          toggleFormType={toggleFormType}
        />
      )}
      {formType === 'signin' && (
        <QuoteModalSignInForm
          prospect={prospect}
          onSuccess={onSuccess}
          toggleFormType={toggleFormType}
        />
      )}
    </Modal>
  );
};

export default QuoteRegisterModal;
