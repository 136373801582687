export const replaceLabelWithContext = (
  label: string,
  context?: Record<string, string>,
): string => {
  if (label.includes('{{c1}}') && context?.c1)
    return label.replace('{{c1}}', context.c1);
  if (label.includes('{{c2}}') && context?.c2)
    return label.replace('{{c2}}', context.c2);
  return label;
};
