import { CellProps, Column, SortingRule } from 'react-table';
import { FC, useEffect, useMemo, useState } from 'react';
import { Col, Flex, Text } from 'components/Layout';
import { isUserAllowed } from 'utils/user';

import { AdminAppRoutes } from 'AdminApp';
import { CustomIcon } from 'theme/styles/icons';
import FooterRowSelect from 'components/Table/FooterRowSelect';
import { IconlyIcon } from 'types/react-iconly';
import { OpportunityStatusButton } from 'components/Buttons';
// Components
import Table from 'components/Table/Table';
import config from 'config/app';
import { format } from 'date-fns';
import { getInitialName } from 'utils/getter';
import { getRouteWithParams } from 'utils/router';
import { getTypeByStatus } from 'utils/status';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchOpportunity from 'container/admin/search/SearchOpportunity';
import { CasesListFilter } from 'container/admin/index';
import { useAuth } from '../../../hooks/useAuth';
import { useApi } from '../../../hooks/useApi';
import {
  ProcedureFull,
  ProcedureStepStatus,
  ProcedureStepType,
  Role,
  SortDirection,
} from '../../../types/resources';
import { Icon } from '../../../components/Images';
import { theme } from '../../../theme';
import CasesListOptionsFilter from '../filters/CasesListOptionsFilter';

export interface OpportunitiesProps {
  setRowsSelected?: (rowsSelected: string[]) => void;
  showFooter?: boolean;
  toggleModal?: (state: boolean) => void;
  stepStatus?: ProcedureStepStatus;
  stepType?: ProcedureStepType | null;
  search?: string | null;
  managerId?: string | null;
  lawyerId?: string | null;
  hasUnprocessedMessages?: boolean | null;
  hasUnprocessedComments?: boolean | null;
  footerIcon?: keyof typeof IconlyIcon | keyof typeof CustomIcon;
  footerText?: string;
  express?: boolean;
  premium?: boolean;
}

const TextEllipsis = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CasesList: FC<OpportunitiesProps> = ({
  setRowsSelected,
  showFooter,
  toggleModal,
  stepStatus,
  stepType,
  search,
  managerId,
  lawyerId,
  hasUnprocessedMessages,
  hasUnprocessedComments,
  footerIcon,
  footerText,
  express,
  premium,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [sortKey, setSortKey] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    undefined,
  );
  const history = useHistory();
  const pageSize = 10;
  const canSelect = isUserAllowed(user?.role, [Role.ADMIN, Role.MANAGER]);
  const { execute: getCases, state: getCasesState } =
    useApi<ProcedureFull[]>(`/procedures`);
  const cases = getCasesState.data?.value ?? [];
  const total = getCasesState.data?.pagination?.total ?? 0;

  useEffect(() => {
    getCases({
      query: {
        search: search || undefined,
        step_type: stepType || undefined,
        step_status: stepStatus || undefined,
        manager_id: managerId || undefined,
        lawyer_id: lawyerId || undefined,
        has_unprocessed_comments: hasUnprocessedComments || undefined,
        has_unprocessed_messages: hasUnprocessedMessages || undefined,
        page: page !== undefined ? page + 1 : 1,
        items: pageSize,
        sort: sortKey,
        direction: sortDirection,
        express: express || undefined,
        premium: premium || undefined,
      },
    });
  }, [
    stepStatus,
    stepType,
    search,
    managerId,
    lawyerId,
    hasUnprocessedComments,
    hasUnprocessedMessages,
    page,
    sortKey,
    sortDirection,
    express,
    premium,
  ]);

  const onLoadMore = ({
    pageIndex,
    sortBy,
  }: {
    pageIndex?: number;
    sortBy?: SortingRule<ProcedureFull>[];
  }) => {
    if (pageIndex !== undefined) {
      setPage(pageIndex);
    }

    const _sortKey = sortBy?.[0]?.id;
    if (_sortKey !== undefined) {
      setSortKey(_sortKey);
    }

    const _sortDesc = sortBy?.[0]?.desc;
    if (_sortDesc !== undefined) {
      setSortDirection(_sortDesc ? SortDirection.DESC : SortDirection.ASC);
    }
  };

  const columns = useMemo<Column<ProcedureFull>[]>(
    () => [
      {
        Header: t('opportunity.columns.reference') || 'Référence',
        accessor: 'reference',
        width: 80,
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <TextEllipsis
            fontStyle="body2"
            content={value.toString()}
            weight="medium"
          />
        ),
      },
      {
        Header: t('opportunity.columns.spouse_leader') || 'Conjoint 1',
        accessor: 'spouse1',
        disableSortBy: true,
        width: 106,
        Cell: ({ value }) => (
          <Text
            fontStyle="body2"
            content={
              value ? `${value?.first_name?.[0]}. ${value?.last_name}` : ''
            }
            weight="medium"
            style={{
              wordWrap: 'break-word',
              width: '100%',
            }}
          />
        ),
      },
      {
        Header: t('opportunity.columns.spouse_follower') || 'Conjoint 2',
        accessor: 'spouse2',
        disableSortBy: true,
        width: 106,
        Cell: ({ value }) => (
          <Text
            fontStyle="body2"
            content={
              value ? `${value?.first_name?.[0]}. ${value?.last_name}` : ''
            }
            weight="medium"
            style={{
              wordWrap: 'break-word',
              width: '100%',
            }}
          />
        ),
      },
      {
        Header: t('opportunity.columns.step') || 'Étape du dossier',
        id: 'step.type',
        accessor: 'current_step',
        disableSortBy: true,
        Cell: ({ value }) => (
          <TextEllipsis
            fontStyle="body2"
            content={t(`opportunity.steps.${value.step}`)}
            weight="medium"
          />
        ),
      },
      {
        Header: t('opportunity.columns.step_status') || 'Statut',
        id: 'step.status',
        accessor: 'current_step',
        disableSortBy: true,
        width: 145,
        Cell: ({ value }) => (
          <OpportunityStatusButton
            type={getTypeByStatus(value.status)}
            text={t(`enums:${value.status}`)}
          />
        ),
      },
      {
        Header: t('opportunity.columns.created_at') || 'Création',
        accessor: 'created_at',
        width: 114,
        Cell: Object.assign(
          ({ cell }: CellProps<ProcedureFull>) => (
            <TextEllipsis
              fontStyle="body2"
              content={format(new Date(cell.value), config.dateFormat)}
              weight="medium"
            />
          ),
          { displayName: 'CreatedAtCell' },
        ),
      },
      {
        Header: t('opportunity.columns.updated_at') || 'Dernière action',
        accessor: 'updated_at',
        Cell: Object.assign(
          ({ cell }: CellProps<ProcedureFull>) => (
            <Text
              fontStyle="body2"
              content={format(new Date(cell.value), 'dd/MM/yyyy HH:mm')}
              weight="medium"
            />
          ),
          { displayName: 'UpdatedAtCell' },
        ),
      },
      {
        Header: t('opportunity.columns.manager') || 'Gestionnaire',
        accessor: 'manager',
        width: 127,
        disableSortBy: true,
        Cell: ({ value }) => (
          <TextEllipsis
            fontStyle="body2"
            content={value ? getInitialName(value.full_name) : undefined}
            weight="medium"
          />
        ),
      },
      {
        Header: t('opportunity.columns.express') || 'Express',
        accessor: 'express',
        width: 100,
        Cell: ({ value }) => (
          <Icon
            name={value ? 'check' : 'close'}
            fill="none"
            primaryColor={value ? theme.colors.green3 : theme.colors.red1}
          />
        ),
      },
      {
        Header: t('opportunity.columns.premium') || 'Premium',
        accessor: 'premium',
        width: 100,
        Cell: ({ value }) => (
          <Icon
            name={value ? 'check' : 'close'}
            fill="none"
            primaryColor={value ? theme.colors.green3 : theme.colors.red1}
          />
        ),
      },
    ],
    [history],
  );
  const onRowClick = (values: Record<string, unknown>) => {
    const route =
      hasUnprocessedMessages !== null && hasUnprocessedMessages !== undefined
        ? AdminAppRoutes.OPPORTUNITY_MESSAGING
        : AdminAppRoutes.CASES_DETAILS;

    history.push(getRouteWithParams(route, { id: values.id as string }));
  };

  const renderFooterSelectRow = () => {
    if (!showFooter || !toggleModal || !footerText || !footerIcon) return null;
    const buttons = [
      {
        onClick: () => toggleModal(true),
        icon: footerIcon,
        text: footerText,
      },
    ];
    return <FooterRowSelect show={showFooter} buttons={buttons} />;
  };
  return (
    <>
      <Flex
        justify="between"
        direction={{ xs: 'column', sm: 'row' }}
        gap={{ xs: 'space16' }}
      >
        <Col xs={12} md={4}>
          <Text
            content={`${t('procedure.title')} (${total})`}
            fontStyle="heading2"
          />
        </Col>
        <Col xs={12} md={4}>
          <SearchOpportunity />
        </Col>
      </Flex>

      <CasesListFilter />

      <Flex paddingTop={{ xs: 'space32' }}>
        <CasesListOptionsFilter />
      </Flex>

      <Flex marginTop={{ xs: 'space32' }}>
        <Table<ProcedureFull>
          loading={getCasesState.loading}
          data={cases || []}
          columns={columns}
          pageSize={pageSize}
          totalCount={getCasesState.data?.pagination?.total || 0}
          loadMore={onLoadMore}
          onRowClick={onRowClick}
          onSelectRow={canSelect ? setRowsSelected : undefined}
          td={{ paddingVertical: 8 }}
        />
        {renderFooterSelectRow()}
      </Flex>
    </>
  );
};

export default CasesList;
