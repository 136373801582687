import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconButton } from 'components/Buttons';
import { DocumentAskModal } from 'components/Modal';
import { Line, Flex, Text } from 'components/Layout';
import { ValidDocumentTemplate } from 'components/Documents/DocumentLink';
import { theme } from 'theme';
import { ListItem } from 'components/List';
import { IconlyIcon } from 'types/react-iconly';
import { ButtonProps } from 'components/Buttons/Button';
import {
  DocumentFull,
  DocumentTemplateId,
  ProcedureSpouseFull,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import useToaster from '../../../hooks/useToaster';
import { formatDocumentTitle } from '../../../utils/format';

interface DocumentRequestProps {
  opportunityId: string;
  countDocumentsInReview: number;
  documents: DocumentFull[];
  spouses: ProcedureSpouseFull[];
  refetchDocuments: () => void;
  editTitle: Dispatch<
    SetStateAction<{
      id: string;
      name: string;
    } | null>
  >;
}

const DocumentRequest: FC<DocumentRequestProps> = ({
  opportunityId,
  countDocumentsInReview,
  documents,
  editTitle,
  spouses,
  refetchDocuments,
}) => {
  const { t } = useTranslation();
  const { success: toasterSuccess } = useToaster();
  const [modalState, setModalState] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [template, setTemplate] = useState<ValidDocumentTemplate>();
  const spousesUsers = spouses.map((s) => s.spouse);

  const {
    execute: sendAskedDocuments,
    state: { loading: sendAskedDocumentsLoading },
  } = useApi(`/procedures/${opportunityId}/documents/asked/send`, {
    method: 'POST',
    onSuccess: refetchDocuments,
  });

  const { execute: askDocument } = useApi(
    `/procedures/${opportunityId}/documents/ask`,
    {
      method: 'POST',
      onSuccess: () => {
        refetchDocuments();
        onClose();
      },
    },
  );

  const { execute: deleteDocument } = useApi(
    `/procedures/${opportunityId}/documents`,
    {
      method: 'DELETE',
      onSuccess: () => {
        toasterSuccess(t('documents.modal.success'));
        refetchDocuments();
      },
    },
  );

  const deleteDocumentCb = (documentId: string) => {
    deleteDocument({
      endpoint: `/procedures/${opportunityId}/documents/${documentId}`,
    });
  };

  const onOpen = () => {
    setModalState(true);
  };

  const onClose = () => {
    setName('');
    setTemplate(undefined);
    setModalState(false);
  };

  const onSubmit = useCallback(
    (data: {
      title: string;
      document_template_id: DocumentTemplateId;
      spouse_id?: string | null;
    }) => {
      askDocument({ body: data });
    },
    [name, template],
  );

  return (
    <>
      <Flex direction={{ xs: 'column' }} width="100%">
        <Flex
          marginBottom={{ xs: 'space16' }}
          justify="between"
          alignItems="center"
        >
          <Text
            fontStyle="heading5"
            weight="bold"
            content={t('documents.being_added')}
          />
          <Text
            fontStyle="body2"
            weight="medium"
            content={t('documents.count', {
              count: documents.length,
            })}
            color={theme.colors.gray6}
          />
        </Flex>
        <Flex
          width="100%"
          marginBottom={{
            xs: documents && documents.length > 0 ? 'space24' : 'none',
          }}
        >
          <Line />
        </Flex>
      </Flex>
      {documents.map((document) => {
        const buttons: Array<ButtonProps | null> = [
          {
            content: '',
            primary: false,
            onClick: () => {
              deleteDocumentCb(document.id);
            },
            iconLeft: {
              name: 'Delete' as keyof typeof IconlyIcon,
            },
          },
        ];
        return (
          <Flex width="100%" marginBottom={{ xs: 'space16' }} key={document.id}>
            <ListItem
              title={formatDocumentTitle(document)}
              iconName="Document"
              buttons={buttons}
              hasLink={true}
              editTitle={() =>
                editTitle({ id: document.id, name: document.title })
              }
            />
          </Flex>
        );
      })}
      <Flex marginTop={{ xs: 'space24' }} justify="between" width="100%">
        {documents.length > 0 ? (
          <IconButton
            iconName="PaperPlus"
            color={theme.colors.black}
            backgroundColor={theme.colors.white}
            border={theme.colors.gray3}
            hoverBackgroundColor={theme.colors.gray2}
            onClick={onOpen}
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <Button
            iconLeft={{ name: 'PaperPlus' }}
            content={t('documents.request_document.button')}
            onClick={onOpen}
          />
        )}

        {documents.length > 0 && (
          <Flex direction={{ xs: 'column' }} alignItems="end">
            <Button
              iconLeft={{ name: 'Send' }}
              content={t('documents.request_document.valide')}
              onClick={() => sendAskedDocuments()}
              $loading={sendAskedDocumentsLoading}
              primary
              disabled={countDocumentsInReview > 0}
            />
            {countDocumentsInReview > 0 && (
              <Text
                fontStyle="body2"
                color={theme.colors.red1}
                content="Veuillez valider tous les documents en vérification"
                marginTop={{ xs: 'space8' }}
              />
            )}
          </Flex>
        )}
      </Flex>
      {modalState && (
        <DocumentAskModal
          opened={modalState}
          spouses={spousesUsers}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default DocumentRequest;
