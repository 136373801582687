import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { md } from 'theme/styles/mediaQueries';
import { theme } from 'theme';
import { FontValue, FontStyles } from 'theme/styles/fonts';
import { weight as weightDefType, Weights } from 'theme/styles/size';

import {
  BreakpointsSpacing,
  ConvertedSpacing,
  printBreakpointRules,
  convertSpacing,
} from 'theme/styles/responsive';
import { hover } from 'theme/styles/hover';

export interface StyledTextProps extends HTMLAttributes<HTMLParagraphElement> {
  width?: string;
  fontSize: string;
  mobileFontSize?: string;
  htmlFor?: string;
  fontWeight: string;
  lineHeight: string;
  lineHeightMobile?: string;
  textTransform: string;
  marginBottom: ConvertedSpacing;
  marginTop: ConvertedSpacing;
  whiteSpace: string;
  marginLeft: ConvertedSpacing;
  marginRight: ConvertedSpacing;
  textAlign?: string;
  mobile: boolean;
  rotate: number;
  fontFamily: FontValue | string;
  decoration: string;
  hoverColor?: string;
}

export const StyledText = styled.p<StyledTextProps>`
  margin: 0;
  font-family: '${(props) => props.fontFamily}';
  width: ${(props) => (props.width ? props.width : 'initial')};
  color: ${(props) => props.color};
  font-size: ${(props) =>
    props.mobile ? props.mobileFontSize : props.fontSize};
  line-height: ${(props) =>
    props.mobile ? props.lineHeightMobile : props.lineHeight};
  font-weight: ${(props) => props.fontWeight};
  text-transform: ${(props) => props.textTransform};
  white-space: ${(props) => props.whiteSpace};
  text-align: ${(props) => props.textAlign};
  text-decoration: ${(props) => props.decoration};
  transition: color 0.5s ease;
  ${(props) =>
    props.rotate &&
    `
 	transform: rotateZ(${props.rotate}deg);
  `};
  ${(props) => printBreakpointRules(props.marginTop)};
  ${(props) => printBreakpointRules(props.marginBottom)};
  ${(props) => printBreakpointRules(props.marginLeft)};
  ${(props) => printBreakpointRules(props.marginRight)};
  ${(props) =>
    md(`
      font-size: ${props.fontSize};
      line-height: ${props.lineHeight};
    `)}
  ${(props) =>
    props.hoverColor &&
    hover(`
      &:hover {
        color: ${props.hoverColor};
      }
    `)}
`;

export interface TextProps extends HTMLAttributes<HTMLParagraphElement> {
  className?: string;
  color?: string;
  content?: string;
  fontStyle: FontStyles;
  htmlFor?: string;
  weight?: Weights;
  marginBottom?: BreakpointsSpacing;
  marginTop?: BreakpointsSpacing;
  marginRight?: BreakpointsSpacing;
  marginLeft?: BreakpointsSpacing;
  whiteSpace?: string;
  textAlign?: string;
  width?: string;
  mobile?: boolean;
  rotate?: number;
  decoration?: string;
  transform?: string;
  dangerouslySetInnerHTML?: { __html: string };
  family?: FontValue;
  hoverColor?: string;
}

export const Text: FC<TextProps> = ({
  width,
  className,
  color,
  content,
  fontStyle,
  htmlFor,
  weight,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  whiteSpace,
  textAlign,
  mobile = false,
  rotate = 0,
  decoration,
  transform,
  dangerouslySetInnerHTML,
  family,
  hoverColor,
  children,
  style,
  onClick,
}) => {
  const { fontSize, lineHeight, fontFamily } = theme.font[fontStyle];
  const fontWeight = weight
    ? weightDefType[weight]
    : theme.font[fontStyle].fontWeight;
  const textTransform = transform || theme.font[fontStyle].textTransform;
  const finalChildren = content ?? children;
  return (
    <StyledText
      onClick={onClick}
      className={className}
      color={color || theme.colors.black}
      width={width}
      htmlFor={htmlFor}
      fontSize={fontSize}
      lineHeight={lineHeight}
      textTransform={textTransform || 'none'}
      fontWeight={fontWeight || '400'}
      marginTop={convertSpacing('margin-top', marginTop)}
      marginBottom={convertSpacing('margin-bottom', marginBottom)}
      marginLeft={convertSpacing('margin-left', marginLeft)}
      marginRight={convertSpacing('margin-right', marginRight)}
      whiteSpace={whiteSpace || 'normal'}
      textAlign={textAlign || 'start'}
      fontFamily={family || fontFamily}
      mobile={mobile}
      rotate={rotate}
      decoration={decoration || 'none'}
      hoverColor={hoverColor}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      style={style}
    >
      {!dangerouslySetInnerHTML && finalChildren !== undefined
        ? finalChildren
        : null}
    </StyledText>
  );
};
