import { FC, useState } from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Linkify from 'linkify-react';

import { Card } from 'components/Cards';
import { theme } from 'theme';
import { Flex, Text } from 'components/Layout';
import { IconButton } from 'components/Buttons';
import NoteTextarea from 'components/Notes/NoteTextarea';

import { Conversation, File } from '../../types/resources';
import TextLink from '../Text/TextLink';
import { Icon } from '../Images';
import { humanFileSize } from '../../utils/math';
import { useApi } from '../../hooks/useApi';
import { downloadFileNew } from '../../utils/downloadFile';

interface DeleteNoteProps {
  noteId: string;
}

interface UpdateNoteProps {
  noteId: string;
  data: {
    content?: string | null;
    isValid?: boolean | null;
  };
}

interface NoteCardProps {
  conversation?: Conversation;
  id: string;
  fullname?: string;
  content: string;
  processed: boolean;
  attachments?: File[];
  onUpdate: (variables: UpdateNoteProps) => void;
  onDelete?: (variables: DeleteNoteProps) => void;
  showOrangeIcon?: boolean;
  createdAt?: string;
  hideRightSide?: boolean;
  icons?: {
    showEdit?: boolean;
    showDelete?: boolean;
    showValid?: boolean;
  };
  editButtonLabel?: string;
}

const StyledCard = styled(Card)`
  border-radius: 12px;
  position: relative;
`;

const IconsContainer = styled.div`
  position: absolute;
  top: 50%;
  right: ${theme.spacing.space24};
  transform: translateY(-50%);
`;

const AttachmentsContainer = styled.div``;

const StatusButton = styled.span`
  padding: ${theme.spacing.space8};
  border-radius: 12px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  background-color: ${theme.colors.green5};
  color: ${theme.colors.green3};
  border: 1px solid ${theme.colors.green4};
`;

const NoteCard: FC<NoteCardProps> = ({
  conversation,
  id,
  fullname,
  content,
  processed,
  attachments,
  onUpdate,
  onDelete,
  showOrangeIcon,
  createdAt,
  hideRightSide = false,
  icons,
  editButtonLabel,
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const hourText = createdAt ? format(new Date(createdAt), 'HH:mm') : '';
  const { execute: downloadFile } = useApi<{ url: string }>(`/files`);

  const downloadAttachment = (attachment: File) => {
    if (conversation) {
      downloadFile({
        endpoint: `/conversations/${conversation.id}/messages/${id}/files/${attachment.id}/download`,
        onSuccess: async (result) => {
          try {
            await downloadFileNew(
              result.value.url,
              attachment.name,
              undefined,
              true,
            );
          } catch (e) {
            console.error(e);
          }
        },
      });
    }
  };

  const handleValidate = () => {
    onUpdate({
      noteId: id,
      data: {
        isValid: true,
      },
    });
  };

  const handleUpdate = (content: string) => {
    onUpdate({
      noteId: id,
      data: { content },
    });
  };

  const handleDelete = () => {
    onDelete?.({
      noteId: id,
    });
  };

  return (
    <>
      {isEditing ? (
        <NoteTextarea
          content={content}
          onCreate={handleUpdate}
          onCancel={() => setIsEditing(false)}
          editButtonLabel={editButtonLabel}
        />
      ) : (
        <StyledCard padding={theme.spacing.space16}>
          <Flex
            alignItems="center"
            marginBottom={{ xs: 'space16' }}
            width="100%"
            justify="between"
          >
            <Flex alignItems="center">
              <IconButton
                iconName="User"
                size={'small'}
                color={
                  showOrangeIcon ? theme.colors.black : theme.colors.green1
                }
                backgroundColor={
                  showOrangeIcon ? theme.colors.salmon1 : theme.colors.green2
                }
                rounded
                stroke="regular"
              />
              <Text
                content={fullname || t('opportunity.notes.automatic')}
                fontStyle="body2"
                marginLeft={{ xs: 'space12' }}
                marginRight={{ xs: 'space12' }}
                weight="medium"
                color={theme.colors.gray6}
              />
              {createdAt && hourText && (
                <Text content={hourText} fontStyle="body3" />
              )}
            </Flex>

            {processed && !hideRightSide && (
              <StatusButton>
                {t('opportunity.notes.status.VALIDATE')}
              </StatusButton>
            )}
          </Flex>

          <Text fontStyle="body2" width="70%">
            <Linkify options={{ target: '_blank', nl2br: true }}>
              {content}
            </Linkify>
          </Text>

          {attachments?.length ? (
            <AttachmentsContainer style={{ marginTop: theme.spacing.space16 }}>
              {attachments.map((a) => {
                const fileSize = humanFileSize(a.size);

                return (
                  <TextLink
                    key={a.id}
                    fontStyle={'body2'}
                    onClick={() => downloadAttachment(a)}
                  >
                    <Icon
                      name="Document"
                      style={{ marginRight: theme.spacing.space4 }}
                    />
                    {a.name} ({fileSize})
                  </TextLink>
                );
              })}
            </AttachmentsContainer>
          ) : null}

          {!hideRightSide && !processed && (
            <IconsContainer>
              <Flex direction={{ xs: 'column' }}>
                {icons?.showValid && (
                  <IconButton
                    iconName="check"
                    color={theme.colors.black}
                    backgroundColor={theme.colors.gray2}
                    rounded
                    stroke="regular"
                    hoverIconColor={theme.colors.salmon1}
                    hoverBackgroundColor={theme.colors.salmon3}
                    onClick={handleValidate}
                  />
                )}

                {icons?.showDelete && (
                  <IconButton
                    iconName="Delete"
                    color={theme.colors.black}
                    backgroundColor={theme.colors.gray2}
                    rounded
                    stroke="regular"
                    hoverIconColor={theme.colors.salmon1}
                    hoverBackgroundColor={theme.colors.salmon3}
                    onClick={handleDelete}
                  />
                )}

                {icons?.showEdit && (
                  <Flex marginTop={{ xs: 'space16' }}>
                    <IconButton
                      iconName="EditSquare"
                      color={theme.colors.black}
                      backgroundColor={theme.colors.gray2}
                      rounded
                      stroke="regular"
                      hoverIconColor={theme.colors.salmon1}
                      hoverBackgroundColor={theme.colors.salmon3}
                      onClick={() => setIsEditing(true)}
                    />
                  </Flex>
                )}
              </Flex>
            </IconsContainer>
          )}
        </StyledCard>
      )}
    </>
  );
};

export default NoteCard;
