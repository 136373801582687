import { useEffect, useMemo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  useSortBy,
  usePagination,
  TableOptions,
  SortingRule,
  useRowSelect,
  CellProps,
  HeaderProps,
  useFlexLayout,
} from 'react-table';
import styled from 'styled-components';

// Components
import { theme } from 'theme';
import { Flex, Text } from 'components/Layout';
import Pagination from 'components/Table/Pagination';
import { Ghost } from 'components/Loading';
import { Icon } from 'components/Images/Icon';
import InputCheckbox from 'components/FormTemplate/Fields/InputCheckbox';
import { getColumnIcon } from 'utils/table';

interface ThProps {
  hasPadding?: boolean;
}

interface TrBodyProps {
  asCard?: boolean;
  hasHover?: boolean;
}

const TableContainer = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
`;

const TrTHead = styled.tr<ThProps>`
  padding: ${({ hasPadding }) =>
    hasPadding ? `0 ${theme.spacing.space24}` : 0};
`;

const Th = styled.th<ThProps>`
  vertical-align: middle;
  padding: 14px 0;
  color: ${theme.colors.gray6};
  text-align: left;
  font-size: 14px;
  font-weight: normal;
`;

type TdProps = {
  paddingHorizontal?: number;
  paddingVertical?: number;
};

const Td = styled.td<TdProps>`
  display: table-cell;
  font-size: 14px;
  font-weight: 500;
  padding: ${({ paddingVertical, paddingHorizontal }) =>
    `${paddingVertical ?? 4}px ${paddingHorizontal ?? 0}px`};
`;

const THead = styled.thead`
  border-bottom: 1px solid ${theme.colors.gray3};
`;

const TBody = styled.tbody`
  display: block;
  width: 100%;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.gray4};
    border-radius: 10px;
  }
`;

const TrTbody = styled.tr<TrBodyProps>`
  border-radius: 11px;
  margin: 12px 0 4px 0;
  display: table;
  width: 100%;

  ${({ asCard }) =>
    asCard &&
    `
      background-color: ${theme.colors.beige}};
      margin-top: ${theme.spacing.space16};
      border-radius: 16px;
      padding: 0 ${theme.spacing.space24}};
  `}

  ${({ onClick }) => onClick && `cursor: pointer;`}

  ${({ asCard }) =>
    !asCard &&
    `
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: ${theme.colors.gray2};
    }
`}

  &:hover {
    background-color: ${({ asCard, hasHover }) =>
      !asCard && hasHover && theme.colors.gray2};
  }
`;

const Span = styled.span`
  margin-left: ${theme.spacing.space8};
`;

export interface TableProps<T extends Record<string, unknown>>
  extends TableOptions<T> {
  loading?: boolean;
  pageSize: number;
  totalCount: number;
  loadMore?: (params: {
    pageIndex?: number;
    sortBy?: SortingRule<T>[];
  }) => void;
  onRowClick?: (values: T) => void;
  onSelectRow?: (ids: string[]) => void;
  asCard?: boolean;
  td?: TdProps;
}

const Table = <T extends Record<string, unknown>>({
  loading,
  data,
  columns,
  pageSize,
  totalCount,
  loadMore,
  onRowClick,
  onSelectRow,
  asCard,
  td,
  hiddenColumns = [],
}: TableProps<T>): ReactElement => {
  const { t } = useTranslation();
  const filteredData = useMemo(() => data, [data]);
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    pageCount,
    state: { pageIndex, sortBy, selectedRowIds },
  } = useTable(
    {
      data: filteredData,
      columns,
      manualSortBy: true,
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageSize),
      autoResetPage: false,
      getRowId: (row) => row.id as string,
      initialState: {
        pageSize,
        hiddenColumns,
      },
      defaultColumn,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      onSelectRow &&
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            accessor: 'id',
            Header: Object.assign(
              ({
                getToggleAllRowsSelectedProps,
                toggleAllRowsSelected,
              }: HeaderProps<T>) => (
                <InputCheckbox
                  {...getToggleAllRowsSelectedProps()}
                  onChange={(e) => {
                    toggleAllRowsSelected(e);
                  }}
                />
              ),
              { displayName: 'AllRowsCheckbox' },
            ),
            width: 30,
            disableSortBy: true,
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: Object.assign(
              ({ row }: CellProps<T>) => (
                <Flex alignItems="center">
                  <InputCheckbox
                    {...row.getToggleRowSelectedProps()}
                    onChange={(e) => {
                      row.toggleRowSelected(e);
                    }}
                  />
                </Flex>
              ),
              { displayName: 'RowCheckbox' },
            ),
          },
          ...columns,
        ]);
    },
  );

  useEffect(() => {
    loadMore?.({ sortBy, pageIndex });
  }, [pageIndex, sortBy]);

  useEffect(() => {
    if (onSelectRow) {
      // For some reason, this will create a loop if we're not checking if ids already exists
      if (Object.entries(selectedRowIds).length !== 0) {
        onSelectRow(Object.keys(selectedRowIds) as string[]);
      } else {
        onSelectRow([]);
      }
    }
  }, [selectedRowIds]);

  return (
    <Flex direction={{ xs: 'column' }} width="100%">
      <Flex
        direction={{ xs: 'column' }}
        width="100%"
        style={{ overflowX: 'auto' }}
      >
        <TableContainer {...getTableProps()}>
          <THead>
            {headerGroups.map((headerGroup) => (
              <TrTHead
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
                hasPadding={!!asCard}
              >
                {headerGroup.headers.map((column) => {
                  const sortByProps =
                    column.Header !== '' &&
                    column.getHeaderProps(column.getSortByToggleProps());

                  return (
                    <Th {...sortByProps} key={column.getHeaderProps().key}>
                      {column.render('Header')}
                      {!column.disableSortBy && (
                        <Span>
                          <Icon
                            name={getColumnIcon(
                              column.isSorted,
                              column.isSortedDesc,
                            )}
                            size="small"
                            stroke={column.isSorted ? 'bold' : 'light'}
                          />
                        </Span>
                      )}
                    </Th>
                  );
                })}
              </TrTHead>
            ))}
          </THead>
          {loading && (
            <tbody>
              {[...Array(pageSize)].map((_, index) => (
                <TrTbody key={index}>
                  <Td colSpan={5}>
                    <Ghost width="100%" height={40} shape="rect" />
                  </Td>
                </TrTbody>
              ))}
            </tbody>
          )}
          {data.length > 0 && !loading ? (
            <TBody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <TrTbody
                    {...row.getRowProps()}
                    key={row.getRowProps().key}
                    asCard={asCard}
                    hasHover={!!onRowClick}
                  >
                    {row.cells.map((cell, i) => (
                      <Td
                        key={cell.getCellProps().key}
                        paddingVertical={td?.paddingVertical}
                        paddingHorizontal={td?.paddingHorizontal}
                        style={{
                          cursor: onRowClick && 'pointer',
                          width: cell.column.width,
                          minWidth: cell.column.minWidth,
                          maxWidth: cell.column.maxWidth,
                          flex: `${cell.column.width} 0 auto`,
                        }}
                        onClick={() => {
                          onRowClick && (!onSelectRow || i !== 0)
                            ? onRowClick(data[row.index])
                            : null;
                        }}
                      >
                        <Flex
                          alignItems="center"
                          height="100%"
                          paddingRight={{
                            xs: i !== row.cells.length - 1 ? 'space32' : 'none',
                          }}
                          style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                          }}
                        >
                          {cell.render('Cell')}
                        </Flex>
                      </Td>
                    ))}
                  </TrTbody>
                );
              })}
            </TBody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={5}>
                  <Text
                    content={t('filter.no_result')}
                    fontStyle="body2"
                    color={theme.colors.red1}
                    marginTop={{ xs: 'space16' }}
                    marginBottom={{ xs: 'space8' }}
                    marginRight={{ xs: 'space16' }}
                    marginLeft={{ xs: 'space16' }}
                  />
                </td>
              </tr>
            </tbody>
          )}
        </TableContainer>
      </Flex>
      {pageCount > 1 && (
        <Pagination
          pageCount={pageCount}
          gotoPage={gotoPage}
          pageIndex={pageIndex}
        />
      )}
    </Flex>
  );
};

export default Table;
